import React, { useState, useEffect } from "react";
import Axios from "axios";
import Loader01 from '../../../shared/Loader01';
import { Link } from 'react-router-dom';
import Pagination from "./Pagination.js";
import PostFeed from './PostFeed';
import Moment from 'react-moment';
//import backgroundImage from '../../../img/Presshero.png';
import { trimHtml } from '../../../utils';
import wordpressUrl from '../../../data/wordpressUrl';

function Blog() {
  const [state, setState] = useState({
    loading: true,
    postss: [],
  });
  const { loading, postss } = state;
  const [posts, setPosts] = useState([]);
  const [page, setPage] = useState(1);
  const [nrofpages, setNumberofpage] = useState(1);
  useEffect(() => {
    Axios.get(wordpressUrl + "/wp-json/wp/v2/posts?categories=2&per_page=100", {
      params: { page: page }
    }).then(response => {
      // Store the number of posible pages.
      setNumberofpage(response.headers["x-wp-totalpages"]);
      // Store the posts from the response.
      setPosts(response.data);
    });
    Axios.get(wordpressUrl + "/wp-json/wp/v2/posts", {
      params: { page: page }
    }).then(response => {
      // Store the number of posible pages.
    //  console.log("post",response.data);
    });
  }, [page, setPosts]);
  let content;

  if (loading) {
    content = <Loader01 />;
  } else {
    content = <PostFeed posts={posts} />;
  }
return (
  <div className="container-fluid py-lg-5 bg-grey">
    {/*<header id="hero" className="masthead mt-lg-4" style={{ backgroundImage: `url(${backgroundImage})` }}>
    </header>*/}
    <div className="container pb-5 ">
        <h1 className="color-blue pt-5 pb-3">Press</h1>
        <div className="row mx-auto">
            {posts &&
            posts.length &&
            posts.map((post, index) => {
            return (
            <div className="col-lg-4 col-md-6 p-3 ">
                <div className="card post">
                    <Moment className="post-meta text-left postdate" format="LL">{post.date}</Moment>
                    <Link to={`/posts/${post.slug}`}>
                    <h2 className="card-title text-left post-title">{post.title.rendered}</h2>
                    </Link>
                    <Link className="btn btn-fitcon btn-left btn-primary btn-sm" to={`/posts/${post.slug}`}>
                    Learn more
                    </Link>
                </div>
            </div>
            );
            })}
        </div>
    </div>

</div>
);
}
export default Blog;
