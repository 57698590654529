import React from 'react';
const Footer01 = () => {

  return (
    <React.Fragment>

      <footer>
        <div className="container py-5" data-aos="fade-up" data-aos-duration="1500">
          <div className="row mx-auto">
            <div className="col-lg-3 col-md-3 mx-auto">
            <a className="color-white" href="/legal-information">LEGAL INFORMATION</a></div>
            <div className="col-lg-3 col-md-3 mx-auto">
            <a className="color-white" href="/terms-of-use">TERMS OF USE</a></div>
            <div className="col-lg-3 col-md-3 mx-auto">
            <a className="color-white" href="/privacy-notice">PRIVACY & COOKIES</a></div>
            <div className="col-lg-3 col-md-3 mx-auto">
            <a className="color-white" href="/japanese-investors">CANYON CAPITAL (JAPAN) K.K.</a></div>
              <div className="col-lg-8 py-3 col-md-10 mx-auto">
              <p className="copyright color-white">
              © {new Date().getFullYear()} Canyon Partners
              </p>
</div>
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
};

export default Footer01;
