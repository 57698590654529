import React, { useState, useEffect } from 'react';
import axios from 'axios';
import HeroSection from './component/HeroSection';
import About from './component/About';
import InvestStrat from './component/InvestStrat';
import Loader01 from '../../shared/Loader01';
import RepresentativeInvest from './component/RepresentativeInvest';
import NavMenu from '../../shared/NavMenu';
import Sliderslick from './component/Sliderslick';
import PostFeed from '../PostsList/component/PostFeed';
import Contact from './component/Contact'
import Searchtest from '../../shared/Searchtest'
import HomePress from './component/HomePress';
import wordpressUrl from '../../data/wordpressUrl';
import SliderHero from "./component/SliderHero";


const Home01 = () => {

  return (
  <React.Fragment>
   <SliderHero />
    <About />
    <InvestStrat />
      <RepresentativeInvest />
{ /* <Sliderslick />*/}
    <HomePress />
    <Contact />
  </React.Fragment>
);
};
export default Home01;
