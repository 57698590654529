import React, { useState } from "react";
import Axios from "axios";
import wordpressUrl from './../data/wordpressUrl';

function Searchtest() {
  const [posts, setPosts] = useState([]);
    const [pages, setPages] = useState([]);
  const [term, setTerm] = useState("");

  const doSearch = () => {
    Axios.get(wordpressUrl + "/wp-json/wp/v2/posts", {
      params: { search: term }
    }).then(function(response) {
      setPosts(response.data);
    });
    Axios.get(wordpressUrl + "/wp-json/wp/v2/pages", {
      params: { search: term }
    }).then(function(response) {
      console.log(response.data);
      setPages(response.data);
    });
  };

  const handleKeypress = e => {
      //it triggers by pressing the enter key
    if (e.keyCode === 13) {
      doSearch();
    }
  };

  return (
    <div className="wp-search__wrapper">
<form action="" autoComplete = "off" >

        <input
          id="search"
          name="search"
          type="text"
          onChange={e => {
            setTerm(e.target.value);
          }}
          value={term}
          placeholder="SEARCH"
          onKeyPress={doSearch}
        />
      <input id="search_submit" type="submit" className="searchicon  " onClick={doSearch} />
  </form>
      <div className="wp-search__results-wrapper">
        {term === "" ? null : posts &&
          posts.length > 0 &&
          posts.map((post, index) => {
            return (
              <a class = "searchlink" href={`/posts/${post.slug}`}>  <div key={post.id} className="wp-search__results-item">
              <h5>{post.title.rendered}</h5>
                  <p dangerouslySetInnerHTML={{ __html:post.excerpt.rendered}}
                />
              { /* <a href={`/posts/${post.slug}`}>Read article</a>*/}
              </div></a>
            );
          })}
          {term === "" ? null : pages &&
            pages.length > 0 &&
            pages.map((page, index) => {
              return (
                <a class = "searchlink" href={`/${page.acf.link_slug}`}>  <div key={page.id} className="wp-search__results-item">
                <h5>{page.title.rendered}</h5>
                    <p dangerouslySetInnerHTML={{ __html:page.excerpt.rendered}}
                  />
                { /* <a href={`/posts/${post.slug}`}>Read article</a>*/}
                </div></a>
              );
            })}
      </div>
    </div>
  );
}
export default Searchtest;
