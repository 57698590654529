import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { HashLink as Link } from 'react-router-hash-link';
import wordpressUrl from '../../../data/wordpressUrl';
const fetchURL = wordpressUrl + "/wp-json/wp/v2/Pages/63";
const getabouts = () => fetch(fetchURL).then(res => res.json());


function About() {
  const [abouts, setabouts] = useState();
  useEffect(() => {
    getabouts().then(data => setabouts(data));
  //  getabouts().then((data) => console.log("ppp",data));
  }, []);

  let aboutsToRender;
  if (abouts) {
    aboutsToRender = [abouts].map(about => {
      return (
        <section>
            <div key={about.id} className="py-lg-5 bg-blue">
                <div id="aboutvalign" className="container">
                    <div className="col-md-10 mx-auto py-5 px-4 " >
                        <h1 className="color-white">
                        <span id="about"></span>{about.acf.about_title}</h1>
                        <p className="color-white" data-aos="fade-up" data-aos-duration="1500" dangerouslySetInnerHTML={{ __html:about.acf.about}}></p>
                    </div>
                </div>
            </div>
        </section>
            )
            });
            }

  return <div>{aboutsToRender}</div>;

              }

export default About;
