import React from 'react';

import Page404Header from './component/Page404Header';

const ErrorPage404 = (location) => {
  return (
    <React.Fragment>
      <Page404Header />
      <div className="container">
        <div className="text-center">
          <p>Sorry, the link you tried could not be found.</p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ErrorPage404;
