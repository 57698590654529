import React from 'react';

const Loader01 = () => {
  return (
    <div className="text-center mb-4">
      <div className="spinner-grow" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};

export default Loader01;
