import React, { useEffect } from 'react';
import './App.css';

import { HashLink as Link } from 'react-router-hash-link';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home01 from './pages/HomePage/Home01';
import Post01 from './pages/Post/Post01';
import NavMenu from './shared/NavMenu';
import ErrorPage404 from './pages/ErrorPage/ErrorPage404';
import Footer01 from './shared/Footer01';
import './styles.scss';
import Blog from './pages/PostsList/component/Blog';
import PrivacyPolicy from './pages/HomePage/component/PrivacyPolicy';
import LegalInformation from './pages/HomePage/component/LegalInformation';
import Termsofuse from './pages/HomePage/component/Termsofuse';
import CanyonJapanKK from './pages/HomePage/component/CanyonJapanKK';

import AOS from "aos";
import "aos/dist/aos.css";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useParams,
  } from "react-router-dom";
import { Navbar,Nav,NavDropdown,Form,FormControl,Button } from 'react-bootstrap'
import ClearCache from "react-clear-cache";
import ReactGA from 'react-ga';
import CookieConsent from "react-cookie-consent";


AOS.init({
   once: true,
   offset: 200,
     duration: 600,
     easing: 'ease-in-sine',
     delay: 100,

});

function App() {
  useEffect(() => {
    ReactGA.initialize('UA-000000-01');
    // To Report Page View
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [])

  useEffect(() => {
   console.log("ga",window.location.pathname)
  })
  return (
    <>
      <CookieConsent
        buttonText="I understand"
        buttonClasses=""
        buttonStyle={{padding: "10px 10px"}}
        expires={30}
        cookieName="myAwesomeCookieName2"
        style={{
          background: "#F0F0F0 " ,
          padding: "10px 10px"

        }}
        buttonStyle={{
          background: "#265171",
          color: "white",
          fontWeight: "bolder",

        }}
      >
        We use cookies only for necessary purposes. Our site cannot function properly without these necessary cookies. These cookies can be disabled only by changing your preferences. You can find more information about cookie use in our privacy policy.{" "}
      {/*  <span style={{ fontSize: "10px" }}>This bit of text is smaller :O</span>*/}
      </CookieConsent>
   <Router>
     <div className="App">

       <NavMenu />
       <Switch>
         <Route path="/" exact component={Home01} />
         <Route path="/posts" exact component={Blog} />
         <Route path="/posts/:slug" exact component={Post01} />
         <Route path="/privacy-notice" exact component={PrivacyPolicy} />
         <Route path="/legal-information" exact component={LegalInformation} />
         <Route path="/terms-of-use" exact component={Termsofuse} />
         <Route path="/japanese-investors" exact component={CanyonJapanKK} />
         <Route component={ErrorPage404} />
       </Switch>
       <Footer01 />
     </div>
   </Router>
   </>
 );
}



export default App;
