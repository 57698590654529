import React from 'react';
import { Link } from 'react-router-dom';

import PostItem01 from './PostItem01';

const PostFeed = (props) => {
const { posts, viewAllPostsLink } = props;

const postItems = posts.slice(0, 3).map((post) => {
const content = (
  <React.Fragment key={post.id}>
      <PostItem01 post={post} />
  </React.Fragment>
  );

return content;
});

let viewMoreBtn;
if (posts) {
if (viewAllPostsLink) {
  viewMoreBtn = (
  <div className="col-12 mt-3 mb-lg-0 mb-3 text-center ">
  <Link className="btn  btn-primary border-lgt" to="/posts">
      Press Archive
      </Link>
  </div>
  );
} else if (props.loadMoreBtnShow) {
  viewMoreBtn = (
  <div className="col-12 text-center">
      {props.loadMoreBtnLoading ? (
      <button className="btn btn-primary" type="button" disabled>
          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
          {` `}
          Load More
      </button>
      ) : (
      <button className="btn btn-primary" onClick={props.onLoadMorePosts}>
          Load More
      </button>
      )}
  </div>
  );
  }
  }

  return (
  <React.Fragment>
      {postItems}
      {viewMoreBtn}
  </React.Fragment>
  );
  };

PostFeed.defaultProps = {
viewAllPostsLink: true,
};
export default PostFeed;
