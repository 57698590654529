import React, { useState, useEffect }  from "react";
// JSX
import HeroSlider, { Slide, Nav, OverlayContainer } from "hero-slider";

import wordpressUrl from '../../../data/wordpressUrl';

const fetchURL =   wordpressUrl +"/wp-json/wp/v2/Pages/38";
const gethero = () => fetch(fetchURL).then(res => res.json());
const SliderHero = () => {

  const [hero, sethero] = useState();

  useEffect(() => {
    gethero().then(data => sethero(data));
  //  gethero().then((data) => console.log("her",data));
  }, []);

  let heroToRender;
  if (hero) {
    heroToRender =[hero].map(hero => {
      // Images
const heroimage1 = hero.acf.hero_background_image[0].image.url;
const heroimage2 = hero.acf.hero_background_image[1].image.url;
const heroimage3 = hero.acf.hero_background_image[2].image.url;
const heroimage4 = hero.acf.hero_background_image[3].image.url;
  return (
    <HeroSlider
      slidingAnimation="fade"
      orientation="horizontal"
      initialSlide={1}

      style={{
        backgroundColor: "rgba(0, 0, 0, 0.33)"
      }}
      settings={{
        sliderFadeInDuration: 500,
        slidingDelay: 200,
        shouldAutoplay: true,
        shouldDisplayButtons: false,
        autoplayDuration: 3000,
        height: "65vh",
        isSmartSliding: true
      }}
    >


      <div id="herovalign" className="container">
        {/*  <div className="col-md-10 reg-box pt-4 pb-4 pl-3 pr-3 mx-auto" data-aos="fade-up" data-aos-duration="1000"  data-aos-delay="0">
              <p className="herosec" dangerouslySetInnerHTML={ { __html:hero.acf.hero_description}}></p>
          </div>*/}
      </div>

      <Slide
        background={{
          backgroundImage: heroimage1,
          backgroundAttachment: "relative",
          backgroundPosition: 'center center',
          backgroundSize: 'cover'
        }}
      />


      <Slide
        background={{
          backgroundImage:heroimage2,
          backgroundAttachment: "relative",
          backgroundPosition: 'center center',
          backgroundSize: 'cover'
        }}
      />

      <Slide
        background={{
          backgroundImage: heroimage3,
          backgroundAttachment: "relative",
          backgroundPosition: 'center center',
          backgroundSize: 'cover'
        }}
      />

      <Slide
        background={{
          backgroundImage: heroimage4,
          backgroundAttachment: "relative",
          backgroundPosition: 'center center',
          backgroundSize: 'cover'
        }}
      />

      <Nav />
    </HeroSlider>
  );
});
}

return <div>{heroToRender}</div>;
}

export default SliderHero;
