import React from 'react';
import { Link } from 'react-router-dom';
import postPlaceholderImage from '../../../img/Presshero.png';

const PostPageHeader01 = (props) => {
const { post } = props;
const featuredMedia = post.featured_media
? post._embedded['wp:featuredmedia']['0'].source_url
: postPlaceholderImage;

return (
<header className="masthead mt-lg-4" style={{ backgroundImage: `url(${featuredMedia})` }}>
    <div className="overlay" />
    <div className="container">
        <div className="row">
            <div className="col-lg-8 col-md-10 mx-auto">

            </div>
        </div>
    </div>
</header>
);
};

export default PostPageHeader01;
