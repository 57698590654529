import React, { useState, useEffect } from 'react';
import wordpressUrl from '../../../data/wordpressUrl';
const fetchURL = wordpressUrl + "/wp-json/wp/v2/Pages/7";
const getcontact = () => fetch(fetchURL).then(res => res.json());

function Contact() {
  const [contact, setcontact] = useState();
  useEffect(() => {
    getcontact().then(data => setcontact(data));
    //getcontact().then((data) => console.log("her",data));
  }, []);

  let contactToRender;
  if (contact) {
    contactToRender = [contact].map(contact => {
      return (

        <div key={contact.id} className=" contact jumbotron jumbotron-fluid px-2 py-0 py-lg-5 pb-5 " style={{ backgroundImage:`url(${contact.acf.background_image.url})`
        }} >
            <div className="overlay" />
              <div className="container"  data-aos="fade-up" data-aos-duration="1500" >
            <div className="container px-5 pt-5 px-sm-2 ">
                <h1 className="color-white"><span id="contact"></span>{contact.title.rendered}</h1>
                <div className="row  pt-3 pb-3 pl-2 pr-2">
                    <div className="col-md-4 reg-box pt-4 pb-4 pl-3 pr-3 mx-auto">
                        <p className="contactsec" dangerouslySetInnerHTML={ { __html:contact.acf.Investor_Relations}}></p>
                    </div>
                </div>
                <div className="row  pt-3 pb-3 pl-2 pr-2">

                    <div className="col-md-4 reg-box pt-4 pb-4 pl-3 pr-3 mx-auto">
                        <p className="contactsec" dangerouslySetInnerHTML={ { __html:contact.acf.originations}}></p>
                    </div>
                </div>
            </div></div>
        </div>
        )
        });
        }

  return <div>{contactToRender}</div>;

}

export default Contact;
