import React, { useState, useEffect } from 'react';
import Searchtest from './Searchtest'
import { NavLink } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import wordpressUrl from './../data/wordpressUrl';
import Dropdown from "./Dropdown";
import './script.js'

function NavMenu() {

  const [isNavVisible, setNavVisibility] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 700px)");
    mediaQuery.addListener(handleMediaQueryChange);
    handleMediaQueryChange(mediaQuery);

    return () => {
      mediaQuery.removeListener(handleMediaQueryChange);
    };
  }, []);

  const handleMediaQueryChange = (mediaQuery) => {
    if (mediaQuery.matches) {
      setIsSmallScreen(true);
    } else {
      setIsSmallScreen(false);
    }
  };

  const toggleNav = () => {
    setNavVisibility(!isNavVisible);
  };

  const fetchURL = wordpressUrl + "/wp-json/wp-api-menus/v2/menus/4";
  const getItems = () => fetch(fetchURL).then(res => res.json());

    const [settings, setSettings] = useState();
    const [items, setItems] = useState(null);

    useEffect(() => {
      getItems().then(data => setItems(data));
      //getItems().then((data) => console.log("menu",data));
    }, []);

    let itemsToRender;
    if (items) {
            return(

            <nav className="navbar navbar-expand-lg bg-white navbar-default navbar-fixed-top "  >
                <a href="/" className="navbar-brand">
                    <img className = "logosize" src={require("./../img/Canyon-Catalyst-Fund-Large.png")}  alt="logo" />
                </a>
  <Searchtest />
<div className = "menuside d-lg-none" >

                <button type="button" className="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
                    <svg className="menumob " xmlns="http://www.w3.org/2000/svg" width="19.679" height="15.5" viewBox="0 0 19.679 15.5">
                        <g id="Group_8" data-name="Group 8" transform="translate(0.839 0.795)">
                            <path id="Line_4" data-name="Line 4" d="M.161.2H17.839" transform="translate(0 0)" fill="none" strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2" />
                            <path id="Line_4_Copy" data-name="Line 4 Copy" d="M.161.2H17.839" transform="translate(0 7.2)" fill="none" strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2" />
                            <path id="Line_4_Copy_2" data-name="Line 4 Copy 2" d="M.161.2H17.839" transform="translate(0 13.5)" fill="none" strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2" />
                        </g>
                    </svg>
                </button></div>

                <div className="collapse navbar-collapse " id="navbarCollapse" >

                    {itemsToRender = items && [items].map((item, index) => {
                    return(
                    <div key={index} className="navbar-nav ml-auto mr-md-5">
                        {/*<a class="nav-item nav-link" href={item.items[0].url}>
                            {item.items[0].title}
                        </a>
                        <a class="nav-item nav-link" href={item.items[1].url}>
                            {item.items[1].title}
                        </a>
                        <a class="nav-item nav-link" href={item.items[2].url}>
                            {item.items[2].title}
                        </a>
                        <a class="nav-item nav-link" href={item.items[3].url}>
                            {item.items[3].title}
                        </a>
                        <a class="nav-item nav-link" href={item.items[4].url}>
                            {item.items[4].title}
                        </a>*/}

                        <li data-toggle="collapse" class = "px-md-2 px-lg-3"><Link to={{pathname: "/", hash:item.items[0].url}} className="nav-item nav-link" >
                        {item.items[0].title}
                        </Link></li>
                        <li data-toggle="collapse" class = "px-md-2 px-lg-3"><Link to={{pathname: "/",hash:item.items[1].url}}  className="nav-item nav-link">
                        {item.items[1].title}
                        </Link></li>
                        <li data-toggle="collapse" class = "px-md-2 px-lg-3"><Link to={{pathname: "/",hash:item.items[2].url}}  className="nav-item nav-link">
                        {item.items[2].title}
                        </Link></li>
                        <li data-toggle="collapse" class = "px-md-2 px-lg-3"><Link to={{pathname: "/",hash:item.items[3].url}} className="nav-item nav-link">
                        {item.items[3].title}
                      </Link></li>
                        <li data-toggle="collapse" class = "px-md-2 px-lg-3"><Link to={{pathname: "/",hash:item.items[4].url}}  className="nav-item nav-link">
                        {item.items[4].title}
                        </Link></li>

                    </div>
                    )})}
                </div>

            </nav>
            );
            }
  return <div>{itemsToRender}</div>;
}
export default NavMenu;
