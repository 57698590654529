import React, { useState, useEffect } from 'react';
import PostFeed from '../../PostsList/component/PostFeed';
import axios from 'axios';
import wordpressUrl from '../../../data/wordpressUrl';
import Loader01 from '../../../shared/Loader01';

  const HomePress = () => {
    const [state, setState] = useState({
      loading: true,
      posts: [],
    });
  const { loading, posts } = state;

  useEffect(() => {
    axios
      .get(  wordpressUrl +'/wp-json/wp/v2/posts/?_limit=3&categories=2')
      .then((res) => {
        const posts = res.data;
        setState({ ...state, posts, loading: false });
      })
    //  .catch((err) => console.log(err));
  }, []);

  let content;
  if (loading) {
    content = <Loader01 />;
  } else {
    content = <PostFeed posts={posts} />;
  }

  return (
<React.Fragment>
    <div className="container-fluid py-lg-5 pb-5 bg-grey" >
        <div className="container bg-grey px-2 pt-5   " data-aos="fade-up" data-aos-duration="1000"  data-aos-delay="0">
            <h1 className="color-blue"> <span id="press"></span>Press </h1>
            <div className="row mx-auto">
                {content}
            </div>
        </div>
    </div>
  </React.Fragment>
 );
}
export default HomePress;
