import React, { Component } from 'react';
import backgroundImage from '../../../img/posts-bg.jpg';

class Page404Header extends Component {
  render() {
    return (
      <header
        className="masthead"
        style={{ backgroundImage: `url(${backgroundImage})` }}>
        <div className="overlay" />
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-10 mx-auto">
              <div className="page-heading">
                <h1>404</h1>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default Page404Header;
