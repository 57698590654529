import React, { useState, useEffect } from 'react';
import wordpressUrl from '../../../data/wordpressUrl';
const fetchURL = wordpressUrl + "/wp-json/wp/v2/Pages/3";
const getinfo = () => fetch(fetchURL).then(res => res.json());

function PrivacyPolicy() {
const [info, setinfo] = useState();

useEffect(() => {
getinfo().then(data => setinfo(data));
//getinfo().then((data) => console.log("ppp",data));
}, []);

let infoToRender;
if (info) {
infoToRender = [info].map(about => {
return (


<div className="container-fluid p-0">
    <header id="hero" className="masthead" style={{ backgroundImage: `url(${about.acf.hero_image.url})` }}>
    </header>

    <div key={about.id} id="about" class="py-lg-5 bg-white">
        <div class="container">

            <div className="row">
                <div className="col-lg-10 col-md-10 mx-auto postcontent">
                    <div className="post-heading">
                        <h2 class="color-blue">{about.title.rendered}</h2>


                    </div>


                    <p className="single-post text-left pt-2" dangerouslySetInnerHTML={{ __html:about.acf.description}}></p>
                </div>
            </div>
        </div>
    </div>
</div>
)
});
}

return <div>{infoToRender}</div>;
}
export default PrivacyPolicy;
