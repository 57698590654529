import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useHistory } from "react-router-dom";
import Loader01 from '../../shared/Loader01';
import PostPageHeader01 from './component/PostPageHeader01';
import ErrorPage404 from '../ErrorPage/ErrorPage404';
import wordpressUrl from '../../data/wordpressUrl';
import { Link } from 'react-router-dom';


const Post01 = (props) => {
const [state, setState] = useState({
loading: true,
post: {},
});
const { loading, post } = state;
let history = useHistory();
useEffect(() => {
const postSlug = props.match.params.slug;

axios
.get(wordpressUrl + `/wp-json/wp/v2/posts/?_embed&slug=${postSlug}`)
.then((res) => {
const post = res.data[0];
setState({ ...state, post, loading: false });
//console.warn(props.match.params.slug);
})
//.catch((err) => console.log(err));
}, [props]);

// createMarkup(post) {
// return { __html: post.content.rendered };
// }

let content;

if (post) {
if (loading) {
content =
<Loader01 />;
} else {
content = (
<React.Fragment>
    <PostPageHeader01 post={post} />

    <article>
        <div className="container">
            <div className="row">
                <div className="col-lg-10 col-md-10 mx-auto postcontent">
                    <div className="post-heading">
                        {' '}
                        <h2 className="color-blue">{post.acf.title}</h2>
                        <p className="color-blue pb-5 ">{post.acf.sub_title}</p>
                        <div className="text-left">    <Link className="btn-link text-left" to="/posts"> <svg xmlns="http://www.w3.org/2000/svg" width="15.034" height="14.871" viewBox="0 0 15.034 14.871">
                                <g id="Group_2" data-name="Group 2" transform="translate(0.534 1.401)">
                                    <path id="Line" d="M.867.5H13.5" transform="translate(0 5.534)" fill="none" stroke="#265171" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" />
                                    <path id="Path_6" data-name="Path 6" d="M0,6.034,4.593,0" transform="translate(0.867 0)" fill="none" stroke="#265171" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2" />
                                    <path id="Path_6-2" data-name="Path 6" d="M0-6.034,4.593,0" transform="translate(0.867 12.069)" fill="none" stroke="#265171" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2" />
                                </g>
                            </svg> Press Archive</Link></div>
                    </div>
                    <div className="single-post text-left pt-2" dangerouslySetInnerHTML={{ __html: post.acf.description }} />
                </div>
            </div>
        </div>
    </article>




</React.Fragment>
);
}
} else {
// Post not found
content =
<ErrorPage404 />;
}

return content;
};

export default Post01;
