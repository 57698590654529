import React, { useState, useEffect } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import wordpressUrl from '../../../data/wordpressUrl';

function RepresentativeInvest() {

  const config = {
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,

          }
        },
        {
          breakpoint: 1000,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
  };

  const fetchURL = wordpressUrl + "/wp-json/wp/v2/Pages/467";
  const getItems = () => fetch(fetchURL).then(res => res.json());

    const [settings, setSettings] = useState(config);
    const [items, setItems] = useState(null);

    useEffect(() => {
      getItems().then(data => setItems(data));

    }, []);

    let itemsToRender;
    if (items) {

  const onChangeCenterMode = e => {
    if (e.target.checked) {
      setSettings({
        ...config,
        centerMode: true,
        centerPadding: '50px'
      });
    } else {
      setSettings(config);
    }
  }
{itemsToRender = [items].map(item => {
  return (

    <div className="py-lg-5 pb-5 bg-white" >
        <div className="container pt-5" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="0">
          <h1 className="color-blue ">  <span id="investments"></span>{item.acf.res_inv_title} </h1>
            <Slider {...config}>
                {item.acf.details.map((itemm, index) => {
                return(<div key={index} className="img-card">
                    <img className="card-img-top" src={itemm.image.url} />
                    <div className="card-body">
                        <div className="card-title color-blue">
                            {itemm.title}</div>
                        <p className="card-text" dangerouslySetInnerHTML={{ __html:itemm.description}} />
                    </div>
                </div>
          )})}
            </Slider>

<p className="contactsec mt-0" dangerouslySetInnerHTML={ { __html:item.acf.contact}}></p>

        </div>
    </div>
);
  })}  }
  return <div>{itemsToRender}</div>;
}

export default RepresentativeInvest;
