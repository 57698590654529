import React from 'react';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import { trimHtml } from '../../../utils';

const PostItem01 = (props) => {
const trimmedHtml = trimHtml(props.post.excerpt.rendered, { limit: 100 });
return (

<div className="col-lg-4 col-md-6 p-3 " data-aos="fade-up" data-aos-duration="1000" data-aos-delay="0" >
    <div className="card post">
        <Moment className="post-meta text-left postdate" format="LL">{props.post.date}</Moment>
        <Link to={`/posts/${props.post.slug}`}>
        <h2 className="card-title text-left post-title">{props.post.title.rendered}</h2>
        </Link>
        <Link className="btn btn-fitcon btn-left btn-primary btn-sm" to={`/posts/${props.post.slug}`}>
        Learn more
        </Link>
    </div>
</div>
);
};

export default PostItem01;
