import React, { useState, useEffect } from "react";
import wordpressUrl from '../../../data/wordpressUrl';
const fetchURL = wordpressUrl + "/wp-json/wp/v2/Pages/671";
const getabouts = () => fetch(fetchURL).then((res) => res.json());
function InvestStrat() {
const [abouts, setabouts] = useState();
  useEffect(() => {
     getabouts().then((data) => setabouts(data));

  }, []);
    let aboutsToRender;
  if (abouts) {
    aboutsToRender = [abouts].map(about => {
      return (

<div key={about.id} className="py-lg-5 bg-grey">
    <div id="aboutvalign" className="container color-blue pt-5"  data-aos="fade-up" data-aos-duration="1000"  data-aos-delay="0">
        <h1 className="color-blue"><span id="strategy"></span>{about.acf.is_title}</h1>

        <div className="row  p-md-3 pt-3 pb-2 pl-2 pr-2">
            <div className="col-md-6 px-md-4">
                  <p className="text-left invest font-weight-bold"dangerouslySetInnerHTML={{ __html:about.acf.is_info[0].is_title}}/>
                <p className=" text-left color-blue py-md-3"dangerouslySetInnerHTML={{ __html:about.acf.is_info[0].is_description}}/>
            </div>
            <div className="col-md-6 px-md-4">
                <p className="text-left invest font-weight-bold"dangerouslySetInnerHTML={{ __html:about.acf.is_info[1].is_title}}/>
                <p className="text-left color-blue py-md-3"dangerouslySetInnerHTML={{ __html:about.acf.is_info[1].is_description}}/>
            </div>

        </div>
    </div>
</div>

);
});
};
return <div>{aboutsToRender}</div>;
}
export default InvestStrat;
